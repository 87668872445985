export default {
  buttons: {
    email: {
      description: "Want to make contact? Send me an e-mail.",
      label: "Send E-mail",
    },
    linkedin: {
      description: "For more details, please visit my Linkedin.",
      label: "Go to LinkedIn",
    },
    resume: {
      description: "For summarized data, check my Resume.",
      label: "Download CV",
    },
    moreDetails: {
      label: "More Details",
    },
  },
  cards: {
    projects: {
      itMerging: {
        title: "IT Merging",
        text: "Merged applications and databases involving two huge companies in the industry. Managed resources and team.",
      },
      jobai: {
        title: "Job Recommender System",
        text: "Recommender System based on the candidate’s resume and the job application requirements.",
      },
      portfolio: {
        title: "Micro Front-End Platform",
        text: "Major application containing SAAS applications. Also managed outsourced company deliveries.",
      },
    },
    specializations: {
      projectManagement: {
        title: "Project Management",
        text: "Issued by Coursera in partnership with Google.",
      },
      deepLearning: {
        title: "Deep Learning",
        text: "Issued by Coursera in partnership with DeepLearning.ai",
      },
      leadingPeople: {
        title: "Leading People and Teams",
        text: "Issued by Coursera in partnership with University of Michigan.",
      },
    },
  },
  generic: {
    specializations: "specialization | specializations | {n} specializations",
    years: "year | years | {name}: {n} years | {n} years ",
  },
  profile: {
    name: "Gustavo Penedo",
    profession: "Computer Engineer",
  },
  skills: {
    management: "Managed celullar teams",
  },
  views: {
    home: {
      title: "Home",
      description1:
        "Proven track record of working with international companies in multiple cultural environments.",
      description2:
        "Played a crucial role in delivering high-quality software solutions with a focus on WEB applications.",
    },
    projects: {
      title: "Projects",
      subtitle: "Work worth mentioning",
    },
    specializations: {
      title: "Specializations",
      subtitle: "I am curious",
    },
    settings: {
      title: "Settings",
      subtitle: "You can tweak a few things",
    },
  },
};
