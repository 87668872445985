<template>
  <div class="vision-component-box-outer">
    <div
      class="vision-material-transition-spatial-move"
      :class="{
        'vision-material-hide': hide,
        'center-absolute': !statusMobile,
      }"
    >
      <Transition name="slide-up" appear>
        <LateralMenu
          v-if="!statusSmallScreen"
          :items="items"
          :vertical="true"
        />
      </Transition>
      <Transition name="slide-up" appear>
        <div
          v-if="!itemChanged"
          class="d-inline-block vision-material-windows-glass-border vision-material-border-radius-46"
        >
          <div
            class="vision-component-box-container vision-material-windows-glass vision-material-border-radius-46"
          >
            <div v-if="itemSelected" class="vision-layout-header">
              <h1 class="vision-layout-header-title">
                {{ $t(itemSelected.title) }}
              </h1>
              <p
                v-if="itemSelected.subtitle"
                class="vision-layout-header-subtitle mb-0"
              >
                {{ $t(itemSelected.subtitle) }}
              </p>
            </div>
            <slot> </slot>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import LateralMenu from "@/components/vision/lateralMenu/LateralMenu.vue";

export default {
  components: {
    LateralMenu,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    hide: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      itemChanged: false,
    };
  },
  computed: {
    itemSelected() {
      return this.items.find((el) => el.key === this.$route.name);
    },
    statusMobile() {
      return this.$store.getters.statusMobile;
    },
    statusSmallScreen() {
      return this.$store.getters.statusSmallScreen;
    },
  },
  watch: {
    itemSelected(val, newVal) {
      if (val !== newVal) {
        this.itemChanged = true;
        setTimeout(() => {
          this.itemChanged = false;
        }, 300);
      }
    },
  },
};
</script>

<style scoped>
@import "@/assets/css/global/visionOS/visionMaterials.css";
@import "@/assets/css/global/visionOS/visionComponents.css";
@import "@/assets/css/global/visionOS/visionLayout.css";

.slide-up-enter-active,
.slide-up-leave-active,
.slide-up-leave-active .vision-material-windows-glass {
  transition: all 0.5s ease-in-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}
.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
