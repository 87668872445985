<template>
  <div
    class="vision-left-menu-outer"
    :class="{
      vertical: vertical,
      stackAtBottom: stackAtBottom,
    }"
  >
    <div class="vision-material-windows-glass-border d-inline-block">
      <div class="vision-left-menu-container vision-material-windows-glass">
        <div
          v-for="item in items"
          :key="item.key"
          class="vision-left-menu-item vision-material-transition-color"
          :class="{
            'vision-material-control-selected': this.$route.name === item.key,
          }"
          @click="selectItem(item.key)"
        >
          <font-awesome-icon
            :icon="item.icon"
            class="center-absolute"
            :class="{
              'vision-text-primary': this.$route.name === item.key,
              'vision-text-tertiary': this.$route.name !== item.key,
            }"
          />
          <Tooltip
            :label="$t(item.label)"
            :position="vertical ? 'right' : 'top'"
          />
        </div>
        <div class="vision-left-menu-item vision-material-transition-color">
          <LocalizationMenu
            class="center-absolute"
            v-model:selectedLanguage="selectedLanguage"
          />
          <Tooltip
            :label="selectedLanguage"
            :position="vertical ? 'right' : 'top'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocalizationMenu from "@/components/navbar/LocalizationMenu.vue";
import Tooltip from "../tooltip/Tooltip.vue";

export default {
  components: {
    LocalizationMenu,
    Tooltip,
  },
  props: {
    items: {
      default: () => [],
    },
    vertical: {
      type: Boolean,
      default: true,
    },
    stackAtBottom: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedLanguage: null,
    };
  },
  methods: {
    selectItem(key) {
      this.$router.push({ name: key });
    },
  },
};
</script>

<style scoped>
.vision-left-menu-outer {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  z-index: 10;
}

.vision-left-menu-outer:not(.vertical) {
  padding-top: 28px;
  /* margin-top: -128px; */
}

.vision-left-menu-outer.vertical {
  padding-right: 28px;
  margin-left: -128px;
}

.vision-left-menu-outer.stackAtBottom {
  position: fixed;
  bottom: 10px;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
}

.vision-left-menu-outer > div {
  border-radius: 100px;
}

.vision-left-menu-container {
  border-radius: 100px;
  padding: 12px;
}

.vision-left-menu-container:hover {
  cursor: pointer;
}

.vision-left-menu-item {
  position: relative;

  width: 44px;
  min-height: 44px;
  height: 44px;

  border-radius: 100px;
}
.vision-left-menu-outer:not(.vertical) .vision-left-menu-item {
  display: inline-flex;
  margin-right: 8px;
}

.vision-left-menu-outer.vertical .vision-left-menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}

.vision-left-menu-item:hover .vision-component-tooltip {
  display: inline-block;
  opacity: 1;
}
.vision-left-menu-item:last-of-type {
  margin-bottom: 0;
}

.vision-left-menu-item:not(.vision-material-control-selected):hover {
  background: var(
    --controls-hover,
    linear-gradient(
      0deg,
      rgba(94, 94, 94, 0.06) 0%,
      rgba(94, 94, 94, 0.06) 100%
    ),
    rgba(255, 255, 255, 0.04)
  );
  background-blend-mode: color-dodge, lighten;
}

.vision-left-menu-item svg {
  font-size: 19px;
}

.vision-left-menu-item .vision-material-control-selected {
  background: var(--controls-selected, #fff);
  background-blend-mode: color-dodge;
}

.vision-left-menu-item:not(:hover) /deep/ .vision-component-tooltip {
  display: none;
}
</style>
