<template>
  <div class="vision-text-primary">
    <div class="profile-img">
      <div class="vision-material-windows-glass-border">
        <img
          class="profile-img"
          alt="Vue logo"
          src="https://i.ibb.co/XxcXwp0/personal.jpg"
        />
      </div>
    </div>
    <div class="text pb-4">
      <p class="mb-2">
        {{ $t("views.home.description1") }}
      </p>
      <p class="mb-3">
        {{ $t("views.home.description2") }}
      </p>
    </div>

    <div class="row ps-4 pe-4">
      <div v-for="tag in tags" class="col-12 col-md-4 pb-2">
        <VisionTag :text="$tc(tag.tag, 2, { name: tag.name, n: tag.amount })" />
      </div>
    </div>

    <div class="row p-4">
      <div v-for="card in cards" class="col-12 col-md-4 pb-4">
        <VisionCard
          :text="$t(`buttons.${card.key}.description`)"
          :button="card.button"
          :icon="card.icon"
          @click="newWindow(card.button.redirectTo)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import homeInfo from "@/data/settings/visionViewHomeConfig";
import VisionCard from "@/components/vision/card/VisionCard.vue";
import VisionTag from "@/components/vision/tag/VisionTag.vue";
import utilsMixin from "@/mixins/utilsMixin";

export default {
  components: { VisionCard, VisionTag },
  mixins: [utilsMixin],
  data() {
    return {
      tags: homeInfo.tags,
      cards: homeInfo.cards,
    };
  },
};
</script>

<style scoped>
.profile-img {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  padding: 20px;
}
.profile-img > div {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.text {
  padding: 0 28.5px;
  /* text-align: justify; */
  opacity: 0.8;
}

@media only screen and (max-width: 768px) {
  .text {
    text-align: justify;
  }
}
</style>
