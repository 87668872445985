export default [
  {
    title: "cards.projects.itMerging.title",
    text: "cards.projects.itMerging.text",
    tags: [
      { text: "Python" },
      { text: "Java" },
      { text: "Oracle" },
      { text: "+6" },
    ],
    button: {
      icon: ["fab", "linkedin"],
      label: "buttons.moreDetails.label",
      redirectTo: "https://www.linkedin.com/in/gustavopenedo/",
    },
  },
  {
    title: "cards.projects.jobai.title",
    text: "cards.projects.jobai.text",
    tags: [
      { text: "Python" },
      { text: "Scala" },
      { text: "Postgres" },
      { text: "+2" },
    ],
    button: {
      icon: ["fab", "linkedin"],
      label: "buttons.moreDetails.label",
      redirectTo: "https://www.linkedin.com/in/gustavopenedo/",
    },
  },
  {
    title: "cards.projects.portfolio.title",
    text: "cards.projects.portfolio.text",
    tags: [
      { text: "Vue.js" },
      { text: "Node.js" },
      { text: "APIs" },
      { text: "+3" },
    ],
    button: {
      icon: ["fab", "linkedin"],
      label: "buttons.moreDetails.label",
      redirectTo: "https://www.linkedin.com/in/gustavopenedo/",
    },
  },
];
