import { createRouter, createWebHistory } from "vue-router";

import ResumeView from "@/views/pages/ResumeView.vue";
import VisionView from "@/views/pages/VisionView.vue";

import VisionHome from "@/views/vision/VisionHome.vue";
import VisionSettings from "@/views/vision/VisionSettings.vue";
import VisionProjects from "@/views/vision/VisionProjects.vue";
import VisionSpecialization from "@/views/vision/VisionSpecialization.vue";

const mainTitle = "Gustavo Penedo";

const routes = [
  {
    path: "/",
    name: "vision",
    component: VisionView,
    children: [
      {
        path: "",
        name: "visionHome",
        component: VisionHome,
        meta: { title: "Home" },
      },
      {
        path: "about",
        name: "visionSettings",
        component: VisionSettings,
        meta: { title: "About" },
      },
      {
        path: "projects",
        name: "visionProjects",
        component: VisionProjects,
        meta: { title: "Projects" },
      },
      {
        path: "specialization",
        name: "visionSpecialization",
        component: VisionSpecialization,
        meta: { title: "Specializations" },
      },
    ],
  },
  {
    path: "/resume",
    name: "resume",
    component: ResumeView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | ${mainTitle}`;
  next();
});

export default router;
