<!-- eslint-disable prettier/prettier -->
<template>
  <div class="visionView noselect">
    <transition name="fade">
      <Loader v-if="loading" />
    </transition>

    <img :src="background" alt="" />
    <ViewContent v-model:items="items" :hide="false">
      <router-view />
    </ViewContent>

    <LateralMenu
      v-if="statusSmallScreen"
      :items="items"
      :vertical="false"
      :stackAtBottom="true"
    />
  </div>
</template>

<script>
import LateralMenu from "@/components/vision/lateralMenu/LateralMenu.vue";
import Loader from "@/components/loaders/Loader.vue";
import ViewContent from "@/components/vision/viewContent/ViewContent.vue";
import visionViewConfig from "@/data/settings/visionViewConfig";

export default {
  components: {
    LateralMenu,
    Loader,
    ViewContent,
  },
  data() {
    return {
      background: visionViewConfig.background,
      items: visionViewConfig.items,
      loading: true,
    };
  },
  computed: {
    statusSmallScreen() {
      return this.$store.getters.statusSmallScreen;
    },
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 6000);
  },
};
</script>

<style scoped>
.visionView {
  overflow: hidden;
  height: 100vh;
  max-height: 100vh;
  max-width: 100vw;
  width: 100%;
  text-align: center;
}

img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  min-width: 100vw;
  -o-object-fit: contain;
  object-fit: cover;
  filter: blur(2px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
